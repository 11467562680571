<template>
	<div>
    <!-- <button @click="updateAllUserDays()">update</button> -->
    <div class="dashboard__container pt-3">
      <Loader v-if="performingRequest" />
      <div class="dashboard__container--body">
        <div class="dashboard__container--body--col alt-col" style="width:100%;">
          <div class="whiteBack">
            <div class="flex align-center justify-space-between mb-3">
              <span>
                <h4>Add User to Pipeline</h4>
              </span>
            </div>
            <div class="flex justify-space-between align-center">
              <ais-instant-search :search-client="searchClient" index-name="a_users" style="width:100%; max-width: 480px;">
                <ais-search-box placeholder="Add User..." />
                <ais-state-results>
                  <template slot-scope="{ state: { query } }">
                    <ais-hits v-show="query.length > 0">
                      <template v-slot:item="{ item }">
                        <div>
                          <button @click="addUser(item)" class="btn btn__icon btn__flat mr-4">
                            </span>
                            <i class="fas fa-plus" style="color:blue;" v-if="!performingRequest"></i>
                            <i class="fa fa-spinner fa-spin" style="color:blue;" v-if="performingRequest"></i>
                          </button>
                          <p style="display: inline;">{{ item.firstName }} {{ item.lastName }} | <span v-if="item.address && item.address">{{item.address.city}} | </span>{{item.email}} | {{item.phone}}</p>
                        </div>
                      </template>
                    </ais-hits>
                  </template>
                </ais-state-results>
              </ais-instant-search>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard__container--body">
        <UserTable :groupUsers="pipeline" :group="eventInfo"  action="removePipeline" :showShiftPreference="true" :eventShifts="eventShifts" />

        <h4 class="mt-5">Hired Pipeline:</h4>

        <UserTable :groupUsers="pipelineHired" :group="eventInfo" class="mt-3" action="addPipeline" :showShiftPreference="true" :eventShifts="eventShifts" />

        <h4 class="mt-5">Cancelled Pipeline:</h4>

        <UserTable :groupUsers="pipelineCancelled" :group="eventInfo" class="mt-3" action="addPipeline" :showShiftPreference="true" :cancelledPipe="true" :eventShifts="eventShifts" />
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import * as moment from 'moment'
import router from '@/router'
import algoliasearch from 'algoliasearch/lite';
import ExportService from "@/services/ExportService"

export default {
  props: ['eventInfo'],
  name: 'eventPipeline',
  data() {
    return {
      performingRequest: false,
      batch: [],
      searchClient: algoliasearch(
        '0T1SIY6Y1V',
        'f03dc899fbdd294d6797791724cdb402',
      ),
    }
  },
  created() {
    this.$store.dispatch('getPipeline', this.$route.params.id)
    this.$store.dispatch('getPipelineCancelled', this.$route.params.id)
    this.$store.dispatch('getPipelineHired', this.$route.params.id)
  },
  components: {
    Loader,
    UserTable
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'pipeline', 'pipelineCancelled', 'pipelineHired', 'eventShifts']),
  },
  methods: {
    addUser(item) {
      console.log(item)
      this.performingRequest = true;
      this.$store.dispatch('addToPipeline', {
        eventId: this.$route.params.id,
        userId: item.objectID
      })
      setTimeout(() => {
        this.performingRequest = false;
        this.$store.dispatch('getPipeline', this.$route.params.id)
      }, 500)
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearPipeline')
    this.$store.dispatch('clearPipelineCancelled')
    this.$store.dispatch('clearPipelineHired')
    this.performingRequest = null
    delete this.performingRequest
    this.batch = null
    delete this.batch
  }
}
</script>

